import type { QueryResult } from '@apollo/client/react';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import { FormFieldVariant } from '@aurora/shared-client/components/form/enums';
import InputEditForm from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import type { FormSpec } from '@aurora/shared-client/components/form/types';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import FormBuilder from '@aurora/shared-client/helpers/form/FormBuilder/FormBuilder';
import type {
  MessageEdge,
  StoreFeaturedContentError
} from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import type { GraphQLError } from 'graphql';
import React, { useContext, useState } from 'react';
import { Modal, useClassNameMapper } from 'react-bootstrap';
import type { ValidateResult } from 'react-hook-form';
import type {
  FeaturedContentWidgetQuery,
  FeaturedContentWidgetQueryVariables,
  MessageViewFragment,
  ProvisionedFeaturedContentMessagesQuery,
  StoreFeaturedContentMutation,
  StoreFeaturedContentMutationVariables
} from '../../../../types/graphql-types';
import EditContext from '../../../context/EditContext/EditContext';
import type { MessageSearchFieldSpec } from '../../../form/MessageSearchField/MessageSearchField';
import MessageSubject from '../../../messages/MessageSubject/MessageSubject';
import useTranslation from '../../../useTranslation';
import EditFeaturedList from '../../EditFeaturedList/EditFeaturedList';
import FeaturedWidgetLastModifiedInfo from '../../FeaturedWidgetLastModifiedInfo/FeaturedWidgetLastModifiedInfo';
import contentQuery from '../FeaturedContentWidget/FeaturedContentWidget.query.graphql';
import storeContentMutation from '../FeaturedContentWidget/StoreFeaturedContent.mutation.graphql';
import useFeaturedContentWidget from '../FeaturedContentWidget/useFeaturedContentWidget';
import formSchema from './AddFeaturedContentModal.form.json';
import localStyles from './AddFeaturedContentModal.module.pcss';

interface MessageSearchFormData {
  /**
   * The selected message
   */
  selectedMessage: MessageViewFragment;
}

interface Props {
  /**
   * Whether to show the modal
   */
  show: boolean;
  /**
   * Callback invoked to hide the modal
   */
  onHide: () => void;
  /**
   * Variables for the FeaturedContentWidget query
   */
  contentQueryVariables: FeaturedContentWidgetQueryVariables;
}

const log = getLog(module);

/**
 * Renders a modal with an input used to search for messages to add to the FeaturedContentWidget
 *
 * @author Jonathan Bridges
 */
const AddFeaturedContentModal: React.FC<React.PropsWithChildren<Props>> = ({
  show,
  onHide,
  contentQueryVariables
}) => {
  const {
    publicConfig: { auroraFeaturedContentTopicMessagesLimit }
  } = useContext(TenantContext);
  const {
    contextNode: { nodeType, title: nodeTitle }
  } = useContext(AppContext);
  const { onChange, quilt } = useContext(EditContext);
  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.ADD_FEATURED_CONTENT_MODAL);
  const { formatMessage, loading: textLoading, FormattedMessage } = i18n;
  const { addToast } = useToasts();
  const [selectedMessages, setSelectedMessages] = useState<Array<MessageViewFragment>>([]);

  /**
   * Updates the selected messages in component state with the original messages stored on the widget
   *
   * @param data the data from the FeaturedContentWidgetQuery result
   */
  function initializeSelectedMessages(
    data: FeaturedContentWidgetQuery | ProvisionedFeaturedContentMessagesQuery
  ): void {
    if ('featuredContentWidget' in data) {
      setSelectedMessages(
        data.featuredContentWidget.messages.edges.map(({ node }: MessageEdge) => node)
      );
    } else {
      setSelectedMessages(data.messages.edges.map(({ node }: MessageEdge) => node));
    }
  }

  const {
    data: contentQueryData,
    loading: contentQueryLoading,
    error: contentQueryError
  }: QueryResult<
    FeaturedContentWidgetQuery,
    FeaturedContentWidgetQueryVariables
  > = useFeaturedContentWidget(
    {
      ...contentQueryVariables,
      first: auroraFeaturedContentTopicMessagesLimit
    },
    !show,
    'no-cache',
    (data: FeaturedContentWidgetQuery) => initializeSelectedMessages(data)
  );

  const [updateFeaturedContent, { loading: updateFeaturedContentMutationLoading }] =
    useMutationWithTracing<StoreFeaturedContentMutation, StoreFeaturedContentMutationVariables>(
      module,
      storeContentMutation
    );

  /**
   * Resets the selected messages and closes the modal
   */
  function onModalClose(): void {
    initializeSelectedMessages(contentQueryData);
    onHide();
  }

  /**
   * Updates the messages used by the featured message component
   */
  async function onUpdateFeaturedMessages(): Promise<void> {
    const messageIds: Array<string> = selectedMessages.map(
      (message: MessageViewFragment) => message.id
    );
    const { instanceId, quiltId, coreNodeId } = contentQueryVariables;
    // immediately persist messages if changes are submitted outside of page builder
    if (!quilt) {
      const { data, errors } = await updateFeaturedContent({
        variables: {
          instanceId,
          messages: messageIds,
          quiltId,
          coreNodeId
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: contentQuery,
            variables: contentQueryVariables
          }
        ]
      });
      if (errors || data?.storeFeaturedContent?.errors) {
        errors?.map((error: GraphQLError) => log.error('error storing featured content', error));
        data?.storeFeaturedContent?.errors?.map((error: StoreFeaturedContentError) =>
          log.error('error storing featured content', error)
        );
        addToast({
          toastVariant: ToastVariant.BANNER,
          alertVariant: ToastAlertVariant.DANGER,
          title: formatMessage('failureTitle'),
          message: formatMessage('failureMessage'),
          autohide: true,
          delay: 4000,
          id: `AddFeaturedContentModal-StoreFeaturedContent-${instanceId}-Error`
        });
        return;
      } else {
        addToast({
          toastVariant: ToastVariant.FLYOUT,
          alertVariant: ToastAlertVariant.SUCCESS,
          title: formatMessage('successTitle'),
          message: formatMessage('successMessage'),
          autohide: true,
          delay: 4000,
          id: `AddFeaturedContentModal-StoreFeaturedContent-${instanceId}-Success`
        });
        onHide();
      }
    } else {
      // store the changes in the page editor session and invoke the mutation during publish
      const sessionInstance: FeaturedWidgetInstance = {
        componentId: EndUserComponent.FEATURED_CONTENT_WIDGET,
        instanceId: contentQueryVariables.instanceId,
        featuredItemIds: messageIds,
        coreNodeId: contentQueryVariables.coreNodeId
      };

      onChange(quilt, null, sessionInstance);
      onHide();
    }
  }

  if (contentQueryError) {
    log.error(
      `error retrieving messages for FeaturedContentWidget with instance id ${contentQueryVariables.instanceId}`,
      contentQueryError.message
    );
  }

  if (textLoading || contentQueryLoading || contentQueryError) {
    return null;
  }

  const messageLimitReachedFeedback: string = formatMessage(
    'AddFeaturedContentModal.selectedMessage.validate.messageLimitCheck.error',
    { limit: auroraFeaturedContentTopicMessagesLimit }
  );

  const isMessagesLimitReached: boolean =
    selectedMessages.length >= auroraFeaturedContentTopicMessagesLimit;

  const messageSearchField: MessageSearchFieldSpec<'selectedMessage', MessageSearchFormData> = {
    fieldVariant: FormFieldVariant.MESSAGE_SEARCH,
    name: 'selectedMessage',
    defaultValue: null,
    useScopeButtons: nodeType !== NodeType.COMMUNITY,
    replaceInputWithSelectedMessage: false,
    useMessageBody: true,
    excludeMessageIds: selectedMessages.map((message: MessageViewFragment) => message.id),
    disabled: isMessagesLimitReached || updateFeaturedContentMutationLoading,
    validations: {
      validate: {
        messageLimitCheck: (): ValidateResult => {
          if (isMessagesLimitReached) {
            return messageLimitReachedFeedback;
          }
        }
      }
    },
    focus: true
  };

  const messageSearchFormSpec: FormSpec<MessageSearchFormData> =
    new FormBuilder<MessageSearchFormData>(
      'AddFeaturedContentModal',
      i18n,
      {
        cx,
        schema: formSchema
      },
      {
        formClassName: cx('lia-g-mb-20')
      }
    )
      .addField(messageSearchField)
      .build();

  /**
   * Renders the timestamp and user login associated with the last modification to the widget contents
   */
  function renderLastModifiedHistory(): React.ReactElement {
    const { lastModified, lastModifiedUser } = contentQueryData.featuredContentWidget;
    return (
      <FeaturedWidgetLastModifiedInfo
        lastModified={lastModified}
        lastModifiedUser={lastModifiedUser.login}
      />
    );
  }

  /**
   * Renders the message contents for use in the drag and drop list
   *
   * @param message the message
   */
  function renderItem(message: MessageViewFragment): React.ReactElement {
    return (
      <>
        <NodeIcon size={IconSize.PX_16} node={message.board} className={cx('flex-shrink-0')} />
        <MessageSubject message={message} as="span" useLink={false} className={cx('lia-g-clamp')} />
      </>
    );
  }

  /**
   * Renders the description that appears the modal header
   */
  function renderDescription() {
    return (
      <span className={cx('lia-description')}>
        <FormattedMessage
          // use a different description text if the modal is rendered inside of page builder
          id={quilt ? 'PageEditor.header.description' : 'header.description'}
          values={{ bold: (chunks: string) => <strong>{chunks}</strong>, nodeType, nodeTitle }}
        />
      </span>
    );
  }

  return (
    <Modal
      show={show}
      onHide={() => onModalClose()}
      size="sm"
      data-testid="AddFeaturedContentModal"
    >
      <Modal.Header closeButton>
        {/*use a different header text if the modal is rendered inside of page builder*/}
        <Modal.Title>{formatMessage(quilt ? 'PageEditor.header' : 'header')}</Modal.Title>
      </Modal.Header>
      {renderDescription()}
      <Modal.Body>
        <InputEditForm<MessageSearchFormData>
          formSpec={messageSearchFormSpec}
          onSubmit={(formData: MessageSearchFormData, actionId) => {
            if (actionId === 'submit' && formData.selectedMessage) {
              setSelectedMessages(previousMessages => [
                formData.selectedMessage,
                ...previousMessages
              ]);
            }
          }}
          submitOnChange={{ watchFields: ['selectedMessage'], waitTime: 0 }}
        />
        {isMessagesLimitReached && (
          <span
            className={cx('lia-g-validation-error lia-feedback')}
            data-testid="AddFeaturedContentModal.Feedback"
          >
            {messageLimitReachedFeedback}
          </span>
        )}
        {selectedMessages.length > 0 && (
          <EditFeaturedList
            items={selectedMessages}
            updateItems={messages => setSelectedMessages(messages)}
            isSubmitting={updateFeaturedContentMutationLoading}
            renderItemComponent={renderItem}
            className={cx('lia-g-mb-20')}
          />
        )}
        {!!contentQueryData?.featuredContentWidget?.lastModifiedUser && renderLastModifiedHistory()}
        <div className={cx('lia-g-modal-buttons-wrap')}>
          <Button
            size="lg"
            loading={updateFeaturedContentMutationLoading}
            onClick={async () => await onUpdateFeaturedMessages()}
            data-testid="AddFeaturedContentModal.Submit.Btn"
          >
            {/*use preview text as submit button text if the modal is rendered inside of page builder*/}
            {formatMessage(quilt ? 'previewBtn' : 'submitBtn')}
          </Button>
          <Button
            variant={ButtonVariant.LIGHT}
            size="lg"
            onClick={() => onModalClose()}
            disabled={updateFeaturedContentMutationLoading}
            data-testid="AddFeaturedContentModal.Cancel.Btn"
          >
            {formatMessage('cancelBtn')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddFeaturedContentModal;
